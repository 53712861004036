import { useTranslation } from "next-i18next";
import Link from "next/link";
import styles from "./gnb.module.css";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { gql, useLazyQuery } from "@apollo/client";
import { DefaultGraphType } from "types/DefaultGraphType";
import { ClassificationType } from "shared/utils/CommonCode";
import { logout } from "shared/api/logout";
import { login } from "shared/api/login";
import { useUserContext } from "shared/user-context";
import { Classification } from "components/types/Classification";
import { CompanyGroup } from "../components/types/CompanyGroup";
import { dateToString } from "../shared/utils/DateUtil";
import { objCookies } from "../shared/utils/CommonUtil";
import { changeUser } from "shared/api/changeUser";

// 로그인 사용자 조회
const GET_USER = gql`
  query GetUser {
    user: getUser {
      loginId
      name
      phone
      company {
        id
        name
        companyGroup {
          id
          name
          domain
          logo {
            id
            name: originFileName
            size
            contentType
          }
        }
      }
    }
  }
`;

// 주관처 목록 조회
const GET_COMPANY_GROUP_LIST = gql`
  query GetCompanyGroupList($request: GetCompanyGroupListInput) {
    companyGroups: getCompanyGroupLists(request: $request) {
      id
      name
      basic
    }
  }
`;

// 분류체계 목록 검색
const GET_CLASSIFICATION_LIST = gql`
  query GetClassificationList($request: GetClassificationListInput) {
    classifications: getClassificationLists(request: $request) {
      id
      name
      type
      depth
      children {
        id
        name
        type
      }
    }
  }
`;

// 중복회원 검색
const GET_DUPLICATION_MEMBER_LIST = gql `
  query getDuplicationMember($request: GetDuplicationMemberInput!) {
    getDuplicationMember(request: $request) {
        id
        loginId
        name
        phone
        email
        company {
            name
        }
    }
  }
`

interface HoverOneDepthProps {
  gnbMenu: GnbMenu;
}

const HoverOneDepth = (props: HoverOneDepthProps) => {
  const { gnbMenu } = props;

  return (
    <li className={styles.depth02List}>
      <div className={styles.depth02Name}>
        <Link href={`/explore?abilityClassification1=${gnbMenu.id}`}>
          <a>{gnbMenu.name}</a>
        </Link>
      </div>
      {/*<ul className={styles.gnbDepth03}>*/}
      {/*  {gnbMenu?.children?.map((m: GnbMenuChildren) => (*/}
      {/*    <HoverTwoDepth*/}
      {/*      key={m.id}*/}
      {/*      gnbMenuId={gnbMenu.id}*/}
      {/*      gnbMenuChildren={m}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</ul>*/}
    </li>
  );
};

interface HoverTwoDepthProps {
  gnbMenuId: string;
  gnbMenuChildren: GnbMenuChildren;
}

const HoverTwoDepth = (props: HoverTwoDepthProps) => {
  const { gnbMenuId, gnbMenuChildren } = props;

  return (
    <li className={styles.depth03List}>
      <div className={styles.depth03Name}>
        <Link
          href={`/explore?abilityClassification1=${gnbMenuId}&abilityClassification2=${gnbMenuChildren.id}`}
        >
          <a>{gnbMenuChildren.name}</a>
        </Link>
      </div>
    </li>
  );
};

interface MenuDefault extends DefaultGraphType {
  id: string;
  name: string;
  type: number;
}

interface GnbMenu extends MenuDefault {
  depth: number;
  children?: Array<GnbMenuChildren>;
}

interface GnbMenuChildren extends MenuDefault {}

interface HoverGnbProps {
  gnbMenus: Array<GnbMenu>;
}

const HoverGnb = (props: HoverGnbProps) => {
  const { t } = useTranslation();
  const { gnbMenus } = props;

  return (
    <ul className={styles.gnbDepth02}>
      <li className={styles.depth02List}>
        <div className={styles.depth02Name}>
          <Link href="/explore">
            <a>{t("전체과정")}</a>
          </Link>
        </div>
      </li>
      {gnbMenus?.map((gnbMenu: GnbMenu) => (
        <HoverOneDepth key={gnbMenu.id} gnbMenu={gnbMenu} />
      ))}
    </ul>
  );
};

interface MobileHamburgerMenuProps {
  close: any;
  gnbMenus: Array<GnbMenu>;
  kirdSpecials: Array<Classification>;
}

// mobile: 햄버거 메뉴
const MobileHamburgerMenu = (props: MobileHamburgerMenuProps) => {
  const { t } = useTranslation();
  const { close, gnbMenus, kirdSpecials } = props;
  const { user } = useUserContext();
  const menu = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { asPath, push } = useRouter();
  // fade
  const [faded, setFaded] = useState(false);

  useEffect(() => {
    // start fade-in transition
    setTimeout(() => setFaded(true), 0);
  }, []);

  // fade-out close
  const closeFade = () => {
    // trigger to close after transition
    setFaded(false);
    menu.current!.addEventListener("transitionend", close);
  };

  // KIRD스페셜 accordion
  const [expandKirdSpecial, setExpandKirdSpecial] = useState(false);

  // 탐색 accordion
  const [expandExplore, setExpandExplore] = useState(false);
  const loginAction = () =>{
      document.cookie =
          "previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `previousUrl=${encodeURIComponent(asPath)}; path=/;`;
      push("/login");
  }


  return (
    <div
      ref={menu}
      className={clsx(
        styles.lytMobileMenu,
        "opacity-5 transition duration-100",
        { "!opacity-100": faded }
      )}
    >
      <div className={styles.menuHeader}>
        <ul className={styles.headQuick}>
          {user?.authenticated && (
            <li className={clsx(styles.item, styles.alarm)}>
              <Link href="/my/alarm">
                <a>{t("알람")}</a>
              </Link>
            </li>
          )}
          <li className={clsx(styles.item, styles.headphone)}>
            <Link href="/support/notice">
              <a>{t("교육지원")}</a>
            </Link>
          </li>
          <li className={clsx(styles.item, styles.setting)}>
            <a onClick={(e) => setAnchorEl(e.currentTarget)}>{t("설정")}</a>
          </li>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            getContentAnchorEl={null}
            elevation={17}
            className={styles.quickMyGroup}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {user?.authenticated && (
               <MenuItem className={styles.myItem}>
                  <h3>My</h3>
               </MenuItem>
            )}
            {user?.authenticated && (
              <MenuItem className={styles.myItem}>
                <Link href="/my/dashboard">
                  <a>My</a>
                </Link>
              </MenuItem>
            )}
            {user?.authenticated && (
              <MenuItem className={styles.myItem}>
                <Link href="/my/favorite">
                  <a>{t("찜관리")}</a>
                </Link>
              </MenuItem>
            )}
            {user?.authenticated && (
              <MenuItem className={styles.myItem}>
                <Link href="/my/schedule">
                  <a>{t("나의일정")}</a>
                </Link>
              </MenuItem>
            )}
            {user?.authenticated && (
              <MenuItem className={styles.myItem}>
                <Link href="/my/profile">
                  <a>{t("내정보")}</a>
                </Link>
              </MenuItem>
            )}
            {user?.authenticated && (
              <MenuItem
                className={styles.myItem}
                onClick={() => {
                  const cookieName = `${dateToString(
                    new Date().toString(),
                    "yyyyMMdd"
                  )}-${String(user?.id)}`;
                  const cookies = objCookies.get({ name: cookieName });

                  if (!!cookies) {
                    objCookies.remove({
                      name: cookieName,
                    });
                  }

                  logout();
                }}
              >
                <Link href="">
                  <a>{t("로그아웃")}</a>
                </Link>
              </MenuItem>
            )}
            {/* NOTE: lsh 계정목록영역 */}
            {/* {user?.authenticated && (
              <MenuItem>
              {duplicationMemberList?.}
              </MenuItem>
            )} */}
            {!user?.authenticated && (
              <MenuItem className={styles.myItem} onClick={() => loginAction()}>
                <Link href="">
                  <a>{t("로그인")}</a>
                </Link>
              </MenuItem>
            )}
          </Menu>
        </ul>
        <button className={styles.btnClose} onClick={closeFade}>
          {t("닫기")}
        </button>
      </div>
      {user && user.id && (
        <div className={styles.menuTop}>
          <div className={styles.menuMessage}>
            <em>
              {user.name} {t("님")}
            </em>
            <i>{t("환영합니다")}!</i>
          </div>
        </div>
      )}
      <div className={styles.menuGroup}>
        <ul className={styles.depth1Group}>
          <li
            className={clsx(styles.depth1List, styles.add, {
              [styles.on]: expandKirdSpecial,
            })}
          >
            <h2
              className={styles.depth1Name}
              onClick={() => setExpandKirdSpecial(!expandKirdSpecial)}
            >
              <a className="cursor-pointer">{t("KIRD 스페셜")}</a>
            </h2>
            <ul
              className={clsx(styles.depth2Group, "hidden", {
                [styles.on]: expandKirdSpecial,
              })}
            >
              <li className={styles.depth2list}>
                <h3 className={styles.depth3Name}>
                  <Link href="/kirdSpecial/guide">{t("2024 KIRD 교육 프로그램")}</Link>
                </h3>
              </li>
              {kirdSpecials?.map((kirdSpecial: Classification) => (
                <li key={kirdSpecial.id} className={styles.depth2list}>
                  <h3 className={styles.depth3Name}>
                    <Link
                      href={`/kirdSpecial/list?kirdSpecialClassification1=${kirdSpecial.id}`}
                    >
                      {kirdSpecial.name}
                    </Link>
                  </h3>
                </li>
              ))}
            </ul>
          </li>
          <li
            className={clsx(styles.depth1List, styles.add, {
              [styles.on]: expandExplore,
            })}
          >
            <h2
              className={styles.depth1Name}
              onClick={() => setExpandExplore(!expandExplore)}
            >
              <a className="cursor-pointer">{t("탐색")}</a>
            </h2>
            <ul
              className={clsx(styles.depth2Group, "hidden", {
                [styles.on]: expandExplore,
              })}
            >
              <li className={styles.depth2list}>
                <h3 className={styles.depth3Name}>
                  <Link href="/explore">{t("전체")}</Link>
                </h3>
              </li>
              {gnbMenus?.map((gnbMenu: GnbMenu) => (
                <li key={gnbMenu.id} className={styles.depth2list}>
                  <h3 className={styles.depth3Name}>
                    <Link href="/explore">{gnbMenu.name}</Link>
                  </h3>
                </li>
              ))}
            </ul>
          </li>
          {user?.authenticated && (
            <li className={styles.depth1List}>
              <h2 className={styles.depth1Name}>
                <Link href="/study">{t("학습")}</Link>
              </h2>
            </li>
          )}
          <li className={styles.depth1List}>
            <h2 className={styles.depth1Name}>
              <Link href="/subscription/home">{t("구독")}</Link>
            </h2>
          </li>
          <li className={styles.depth1List}>
            <h2 className={styles.depth1Name}>
              <Link href="/community/learningLab">{t("커뮤니티")}</Link>
            </h2>
          </li>
        </ul>
      </div>
    </div>
  );
};

/**
 * GnbMenu
 * @constructor
 */
export default function KirdGnb() {
  const { t } = useTranslation();
  const { route, pathname, asPath , query, isReady, push } = useRouter();
  const { user } = useUserContext();
  const gnbRef = useRef<HTMLLIElement>(null);
  const gnbMyRef = useRef<HTMLLIElement>(null);
  const keywordRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // 햄버거 메뉴 활성화 여부
  const [openHamburger, setOpenHamburger] = useState(false);

  // 검색 영역 활성화 여부
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  // 검색어
  const [keyword, setKeyword] = useState<string>("");

  // 로그인 사용자 주관처
  const [companyGroup, setCompanyGroup] = useState<CompanyGroup>(null);

  // 햄버거 메뉴 열림 상태에 따라 바디 오버플로우 조절
  useEffect(() => {
    if (openHamburger) {
      // 햄버거 메뉴가 열릴 때 바디의 오버플로우를 숨깁니다
      document.body.style.overflow = 'hidden';
    } else {
      // 햄버거 메뉴가 닫힐 때 바디의 오버플로우를 원래대로 돌립니다
      document.body.style.overflow = 'auto';
    }

    // 컴포넌트 언마운트 시에도 오버플로우를 원래대로 돌립니다
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openHamburger]);
  

  // 로그인 사용자 조회
  const [fnGetUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.user?.company?.companyGroup) {
        setCompanyGroup(data.user.company.companyGroup);

        let getGnbMenuListRequest: any = {
          types: [ClassificationType.ABILITY],
          depth: 1,
          companyGroupId: data.user.company.companyGroup.id,
          year: new Date().getFullYear(),
        };

        // GNB 메뉴 목록 조회
        fnGetGnbMenuList({
          variables: { request: { ...getGnbMenuListRequest } },
        });


      } else {
        // 주관처 목록 조회
        fnGetCompanyGroupList({ variables: { request: { basic: true } } });
      }

      // 중복회원 검색
      data.user.name !== null && data.user.phone !== null && fnGetDuplicationMemberList({ variables: { request: { name: data.user.name, phone: data.user.phone } }})
      
      let getKirdSpecialListRequest: any = {
        types: [ClassificationType.KIRD_SPECIAL],
        depth: 1,
      };

      // KIRD스페셜 목록 검색
      fnGetKirdSpecialList({
        variables: { request: { ...getKirdSpecialListRequest } },
      });
    },
    onError: () => {
      // 주관처 목록 조회
      fnGetCompanyGroupList({ variables: { request: { basic: true } } });
    },
  });

  // 주관처 목록 조회
  const [fnGetCompanyGroupList] = useLazyQuery(GET_COMPANY_GROUP_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.companyGroups?.length > 0) {
        let getGnbMenuListRequest: any = {
          types: [ClassificationType.ABILITY],
          depth: 1,
          companyGroupId: data.companyGroups[0]?.id,
          year: new Date().getFullYear(),
        };

        // GNB 메뉴 목록 조회
        fnGetGnbMenuList({
          variables: { request: { ...getGnbMenuListRequest } },
        });
      }
    },
  });

  // GNB 메뉴 목록 조회
  const [
    fnGetGnbMenuList,
    { loading: gnbMenusLoading, error: gnbMenusError, data: gnbMenusData },
  ] = useLazyQuery(GET_CLASSIFICATION_LIST, {
    fetchPolicy: "no-cache",
  });

  // GNB 메뉴 목록
  const gnbMenus = gnbMenusData?.classifications;

  // KIRD스페셜 목록 조회
  const [
    fnGetKirdSpecialList,
    {
      loading: kirdSpecialsLoading,
      error: kirdSpecialsError,
      data: kirdSpecialsData,
    },
  ] = useLazyQuery(GET_CLASSIFICATION_LIST, {
    fetchPolicy: "no-cache",
  });

  // KIRD스페셜 목록
  const kirdSpecials = kirdSpecialsData?.classifications;

    // 중복회원 조회
    const [
      fnGetDuplicationMemberList,
      { loading: duplicationMemberListLoading, error: duplicationMemberListError, data: duplicationMemberListData },
    ] = useLazyQuery(GET_DUPLICATION_MEMBER_LIST, {
      fetchPolicy: "no-cache",
    });
  
    // 중복회원 목록
    const duplicationMemberList = duplicationMemberListData?.getDuplicationMember;

  useEffect(() => {
    if (isReady) {
      // 로그인 사용자 조회
      fnGetUser();
    }
  }, [query]);

  // 통합 검색
  const fnTotalSearch = () => {
    setOpenSearch(false);

    push(
      {
        pathname: "/search/ALL",
        query: {
          keyword: keyword,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  useEffect(() => {
    if (openSearch) {
      keywordRef?.current.focus();
    }
    if (openSearch && !keyword && query.totalSearchKeyword) {
      setKeyword(query.totalSearchKeyword as string);
    }
  }, [openSearch]);

  const loginAction = () =>{
      document.cookie =
          "previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `previousUrl=${encodeURIComponent(asPath)}; path=/;`;
      push("/login");
  }


    const btnSearchRef = useRef<HTMLButtonElement>(null);

  const btnAlarmRef = useRef<HTMLButtonElement>(null);
  const btnSupportRef = useRef<HTMLButtonElement>(null);

  return (
    <React.Fragment>
            <div className={clsx(styles.lytTopSearch, {[styles.on]: openSearch})}>
                <div className={styles.searchBar}>
                    <div className={clsx(styles.searchBarArea, "flex")}>
                        <div className={styles.searchBtnArea}>
                            <button
                                className={styles.searchBtn}
                                type="button"
                                onClick={fnTotalSearch}
                            >
                                {t('검색')}
                            </button>
                        </div>
                        <div className={styles.searchInputArea}>
                            <input
                                className={styles.searchInput}
                                type="text"
                                title={t('검색어 입력')}
                                placeholder={t('검색어를 입력해 주세요')}
                                ref={keywordRef}
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        fnTotalSearch();
                                    }
                                }}
                            />
                            <button
                                className={styles.delText}
                                type="button"
                                onClick={() => setKeyword("")}
                            >
                                {t('검색어 삭제')}
                            </button>
                        </div>
                        <button
                            className={styles.closeSearch}
                            onClick={() => setOpenSearch(false)}
                            onBlur={() => {
                                setOpenSearch(false)
                                if (user?.authenticated) {
                                    btnAlarmRef?.current?.focus();
                                } else {
                                    btnSupportRef?.current?.focus();
                                }
                            }}
                        >
                            {t('닫기')}
                        </button>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.lytGnb, "sticky top-0 z-20 bg-white border-b", {[styles.blue]: pathname === "/", "hidden lg:block": pathname !== "/"})}>
                <div className={styles.gnbArea}>
                    <div className={styles.gnbLogo}>
                        <h1>
                            <a href="/">
                                <img
                                    className={'w-[220px] h-[50px] cursor-pointer'}
                                    alt={companyGroup ? companyGroup?.name : t('ALPHA CAMPUS 과학기술인 알파캠퍼스')}
                                    src={companyGroup?.logo?.id ? `/api/file/${companyGroup?.logo?.id}?accessToken=ok` : '/images/icon/img-logo-b_2.svg'}
                                    onError={(e) => e.currentTarget.src = '/images/common/img-320-list-noimg.png'}
                                />
                            </a>
                        </h1>
                    </div>                    
                    <div className={styles.gnbWrap}>
                        <div className={styles.gnbMenu}>
                            <ul className={styles.gnbDepth01}>
                                <li className={clsx(styles.depth01List, {[styles.on]: route.startsWith("/kirdSpecial/guide")})}
                                    onFocus={(e) => gnbRef.current!.classList.remove(styles.active)}
                                >
                                    <div className={styles.depth01Name}>
                                        <Link href="/kirdSpecial/guide">{t("KIRD 스페셜")}</Link>
                                    </div>
                                </li>
                                <li
                                    className={clsx(styles.depth01List, {[styles.on]: route.startsWith("/explore")})}
                                    ref={gnbRef}
                                    onMouseOver={(e) => gnbRef.current!.classList.add(styles.active)}
                                    onMouseOut={(e) => gnbRef.current!.classList.remove(styles.active)}
                                    onFocus={(e) => gnbRef.current!.classList.add(styles.active)}
                                >
                                    <div className={styles.depth01Name}>
                                        <Link href="/explore">{t("탐색")}</Link>
                                    </div>
                                    <div className={styles.depth02Group}>
                                        <div className={styles.depth02Area}>
                                            <div className={styles.depth02Box}>
                                                <HoverGnb gnbMenus={gnbMenus}/>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {user?.authenticated &&
                                    <li className={clsx(styles.depth01List, {[styles.on]: route.startsWith("/study")})}
                                        onFocus={(e) => gnbRef.current!.classList.remove(styles.active)}
                                    >
                                        <div className={styles.depth01Name}>
                                            <Link href="/study" >{t("학습")}</Link>
                                        </div>
                                    </li>
                                }
                                <li className={clsx(styles.depth01List, {[styles.on]: route.startsWith("/subscription")})}
                                    onFocus={(e) => gnbRef.current!.classList.remove(styles.active)}
                                >
                                    <div className={styles.depth01Name}>
                                        <Link href="/subscription/home">{t("구독")}</Link>
                                    </div>
                                </li>
                                <li className={clsx(styles.depth01List, {[styles.on]: route.startsWith("/community")})}
                                    onFocus={(e) => gnbRef.current!.classList.remove(styles.active)}
                                >
                                    <div className={styles.depth01Name}>
                                        <Link href="/community/learningLab">{t("커뮤니티")}</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.gnbQuick}>
                            <ul className={styles.quickMenu}>
                                <li className={styles.quickSearch}>
                                    <button
                                        ref={btnSearchRef}
                                        className={styles.btnSearch}
                                        type="button"
                                        onClick={(e) => {
                                            setOpenSearch(!openSearch)
                                        }}
                                    >
                                        {t("검색")}
                                    </button>
                                </li>
                                {user?.authenticated &&
                                    <li className={styles.quickAlarm}>
                                        <Link href="/my/alarm">
                                            <button ref={btnAlarmRef} className={styles.btnAlarm}>{t("알람")}</button>
                                        </Link>
                                    </li>
                                }
                                <li className={clsx("xl:hidden", styles.gnbAllmenu)}>
                                    <button
                                        className={styles.btnAllmenu}
                                        onClick={() => setOpenHamburger(true)}
                                    >
                                        {t("햄버거")}
                                    </button>
                                </li>
                                <li className={clsx('hidden xl:block', styles.quickSupport)}>
                                    <Link href="/support/notice">
                                        <button ref={btnSearchRef} className={styles.btnSupport}>{t('학습지원')}</button>
                                    </Link>
                                </li>
                                <li className={clsx("hidden xl:block", styles.quickMy)}
                                    ref={gnbMyRef}
                                    onMouseOver={(e) => gnbMyRef.current!.classList.add(styles.active)}
                                    onMouseOut={(e) => gnbMyRef.current!.classList.remove(styles.active)}
                                    onFocus={(e) => gnbMyRef.current!.classList.add(styles.active)}
                                >
                                    <button className={styles.btnMy}>
                                        User
                                    </button>
                                    <div className={styles.quickMyGroup}>
                                        <ul className={styles.quickMyListGroup}>
                                            {user?.authenticated &&
                                              <li className={clsx(styles.myItem, styles.myItemTitle)}><h3>My</h3></li>
                                            }
                                            {user?.authenticated &&
                                                <li className={styles.myItem} onClick={() => push("/my/dashboard")} tabIndex={0}><a>My</a></li>
                                            }
                                            {user?.authenticated &&
                                                <li className={styles.myItem} onClick={() => push("/my/favorite")} tabIndex={0}><a>{t('찜관리')}</a></li>
                                            }
                                            {user?.authenticated &&
                                                <li className={styles.myItem} onClick={() => push("/my/schedule")} tabIndex={0}><a>{t("나의일정")}</a></li>
                                            }
                                            {user?.authenticated &&
                                                <li className={styles.myItem} onClick={() => push("/my/profile")} tabIndex={0}><a>{t("내정보")}</a></li>
                                            }
                                            {user?.authenticated &&
                                                <li
                                                    className={styles.myItem}
                                                    onClick={() => {
                                                        const cookieName = `${dateToString(new Date().toString(), 'yyyyMMdd')}-${String(user?.id)}`;
                                                        const cookies = objCookies.get({name: cookieName});

                                                        if (!!cookies) {
                                                            objCookies.remove({
                                                                name: cookieName,
                                                            });
                                                        }

                                                        logout();
                                                    }}
                                                  tabIndex={0}
                                                  onBlur={() => gnbMyRef.current!.classList.remove(styles.active)}
                                                ><a>{t("로그아웃")}</a></li>
                                            }
                                            {/* NOTE: lsh 기능은 정상이지만 KIRD 요청으로 인해 주석처리 */}
                                            {/* {user?.authenticated &&
                                             <>
                                              <li className={clsx(styles.myItem, styles.myItemTitle)}><h3>내 계정</h3></li>
                                              {duplicationMemberList?.filter((v)=>v.id == user?.id)?.map((dupUser, idx) => {
                                                return (
                                                  <li className={clsx(styles.myItem, styles.duplicationList, user.loginId === dupUser.loginId ? styles.sameId : "")} key={idx}>
                                                    <a onClick={ ()=> {
                                                      
                                                        const cookieName = `${dateToString(new Date().toString(), 'yyyyMMdd')}-${String(user?.id)}`;
                                                        const cookies = objCookies.get({name: cookieName});

                                                        if (!!cookies) {
                                                            objCookies.remove({
                                                                name: cookieName,
                                                            });
                                                        }

                                                        changeUser(dupUser.loginId);
                                                    }}>
                                                      {dupUser.loginId}
                                                    </a>
                                                  </li>
                                                  )
                                                })}
                                              </>
                                            } */}
                                            {!user?.authenticated &&
                                                <li
                                                    className={styles.myItem}
                                                    onClick={() => loginAction()}
                                                tabIndex={0}
                                                ><a>{t("로그인")}</a></li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                <li className={clsx('hidden xl:block', styles.quickSitemap)}>
                                    <Link href="/sitemap">
                                        <button className={styles.btnSitemap}>{t('사이트맵')}</button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {openHamburger && (
                    <MobileHamburgerMenu
                        close={() => setOpenHamburger(false)}
                        gnbMenus={gnbMenus}
                        kirdSpecials={kirdSpecials}
                    />
                )}
            </div>
        </React.Fragment>
  );
}
